import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import Container from '@components/Container'
import { FullVideoWrapper, FullVideoVideo, FullVideoText } from './index.style'
import { Heading2, Super1 } from '@components/TextStyles'
import VimeoPlayer from '@components/VimeoPlayer'
import Spacer from '@components/Spacer'
import AnimateSplitText from '@components/animation/AnimateSplitText'

const FullVideo = ({ id, title, subtitle, video }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0% 0% 0% 0%',
  })

  return (
    <FullVideoWrapper ref={ref} id={id}>
      <FullVideoVideo>
        {video && (
          <VimeoPlayer
            id={video}
            options={{
              background: true,
              autoplay: false,
              quality: '1080p',
            }}
            playState={inView}
          />
        )}
      </FullVideoVideo>
      <FullVideoText>
        <Container>
          <Super1>
            <AnimateSplitText>{title}</AnimateSplitText>
          </Super1>
          <Spacer size={35} />
          <Heading2>
            <AnimateSplitText>{subtitle}</AnimateSplitText>
          </Heading2>
        </Container>
      </FullVideoText>
    </FullVideoWrapper>
  )
}

FullVideo.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  video: PropTypes.string,
}

export default FullVideo
