import { matchContainerPadding } from '@components/Container/index.style'
import { IconButtonMain } from '@components/IconButton/index.style'
import { ImageContentWrapper } from '@components/ImageContent/index.style'
import { RoundedImageWrapper } from '@components/RoundedImage/index.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { grid } from '@styles/vars/grid.style'
import { Heading1, Label, TextBodyLarge } from '@styles/vars/textStyles.style'
import styled from 'styled-components'
import { clamp, getClampValue } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'

export const CarouselWrapper = styled.div`
  background-color: ${props => props.bgColor};
  overflow: hidden;
  ${clamp('padding-bottom', 100, 110)};
  ${clamp('padding-top', 72, 110)};
  position: relative;

  ${ImageContentWrapper} + & {
    ${clamp('padding-top', 0, 110)};
  }
`

export const CarouselContentsOuter = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${mq.tabletL} {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`

export const CarouselContentsInner = styled.div``

export const CarouselContents = styled.div`
  display: grid;
  overflow: hidden;
  ${matchContainerPadding('margin-left', true)};
  ${matchContainerPadding('margin-right', true)};
  ${matchContainerPadding('padding-left')};
  ${matchContainerPadding('padding-right')};
  position: relative;

  ${mq.desk} {
    overflow: visible;
  }
`

export const CarouselContent = styled.div.attrs(props => ({
  style: {
    pointerEvents: props.index !== 0 ? `none` : `auto`,
    opacity: props.index === 0 ? 1 : 0,
    transform: `translateX(${20 * props.index}%)`,
    transition: `opacity 0.6s ${
      props.index !== 0 ? cubicBezier.out : cubicBezier.inOut
    } ${props.index !== 0 ? 0 : 0.4}s, transform 1s ${
      cubicBezier.inOut
    }, visibility 0s ${props.index !== 0 ? 1 : 0}s`,
    visibility: props.index !== 0 ? `hidden` : `visible`,
  },
}))`
  grid-area: 1 / 1;
`

export const CarouselContentInner = styled.div`
  ${Heading1} {
    max-width: 7em;
  }
  ${TextBodyLarge} {
    max-width: 17em;
  }
`

export const CarouselControls = styled.div`
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
  text-align: center;

  ${mq.tabletL} {
    margin-bottom: 0;
    margin-top: 3rem;
    text-align: left;
  }

  ${IconButtonMain}:nth-child(1) {
    margin-right: 1.2rem;
    transform: rotateZ(180deg);
  }

  svg {
    ${clamp('height', 14, 16)};
    ${clamp('width', 14, 16)};
  }
`

export const CarouselSubSlides = styled.ul`
  display: none;
  ${clamp('padding-top', 20, 30, breakpoints.desk, breakpoints.contained)};

  ${mq.desk} {
    display: block;
  }
`

export const CarouselSubSlide = styled.li`
  display: inline-block;
  ${clamp('font-size', 13, 16, breakpoints.desk, breakpoints.contained)};
  margin-bottom: 0.8rem;
  padding-left: 1.8rem;
  position: relative;
  width: 50%;

  ${mq.deskL} {
    width: 100%;
  }

  &:before {
    background-color: ${colors.blue};
    border-radius: 50%;
    content: '';
    display: block;
    height: 0.6rem;
    left: 0;
    margin-top: -0.3rem;
    position: absolute;
    top: 50%;
    width: 0.6rem;
  }
`

export const CarouselImages = styled.div`
  position: relative;
`

export const CarouselImage = styled.div.attrs(props => ({
  style: {
    opacity: props.index < 0 ? 0 : 1,
    pointerEvents: props.index < 0 ? `none` : `auto`,
    transform: `translateX(${
      100 * props.index
    }%) translateX(calc(${getClampValue(grid.gap.min, grid.gap.max)} * ${
      props.index
    }))`,
    transition: `opacity ${props.index < 0 ? 0.3 : 0.6}s ${
      props.index < 0 ? cubicBezier.out : cubicBezier.inOut
    } ${props.index < 0 ? 0 : 0.4}s, transform 1s ${cubicBezier.inOut}`,
  },
}))`
  will-change: transform;

  &:nth-child(1n + 2) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${RoundedImageWrapper} > div {
    transform: scale(${props => (props.index < 1 ? 1 : 1.2)});
    transition: ${props =>
      props.index < 1
        ? `transform 1.5s ${cubicBezier.inOut}`
        : `transform 0.7s ${cubicBezier.out} 0.3s`};

    &:before {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      content: '';
      left: 0;
      ${clamp('height', 60, 120)};
      position: absolute;
      right: 0;
      top: 0;
      transform: translateZ(0);
      z-index: 2;
    }
  }

  ${Label} {
    opacity: ${props => (props.index === 0 ? 1 : 0)};
    position: absolute;
    ${clamp('left', 24, 48)};
    ${clamp('top', 20, 44)};
    transition: opacity 1s ${cubicBezier.out};
    z-index: 3;
  }
`
