import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useSwipeable } from 'react-swipeable'
import {
  CarouselModalWrapper,
  CarouselModalClose,
  CarouselModalControls,
  CarouselModalImages,
  CarouselModalImage,
  CarouselModalContents,
  CarouselModalContent,
  CarouselModalContentInner,
} from './index.style'
import Grid from '../Grid'
import GridItem from '../GridItem'
import RichText from '../RichText'
import RoundedImage from '@components/RoundedImage'
import Container from '@components/Container'
import gsap from 'gsap'
import { Heading2 } from '@components/TextStyles'
import IconButton from '@components/IconButton'
import { colors } from '@styles/vars/colors.style'
import IconCarouselArrow from '@svgs/IconCarouselArrow'
import Modal, { modalAnimationDurationS } from '@components/Modal'
import Close from '@svgs/Close'
import { breakpoints } from '@styles/vars/breakpoints.style'

const CarouselModal = ({ isOpen, closeModal, slides, active }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const contentWrap = useRef()
  const contentInners = useRef([])

  const previous = () => {
    if (activeIndex !== 0) setActiveIndex(activeIndex - 1)
  }

  const next = () => {
    if (activeIndex < slides.length - 1) setActiveIndex(activeIndex + 1)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (window.innerWidth < breakpoints.desk) {
        next()
      }
    },
    onSwipedRight: () => {
      if (window.innerWidth < breakpoints.desk) {
        previous()
      }
    },
  })

  useEffect(() => {
    const animateDelay = 0.01 // this is required so that Reach modal is rendered before animation is attempted

    gsap.delayedCall(animateDelay, () => {
      if (!contentWrap.current || !contentInners.current[activeIndex]) return

      const height =
        contentInners.current[activeIndex].getBoundingClientRect().height

      gsap.to(contentWrap.current, {
        height: height,
        duration: 0.5,
      })
    })
  }, [isOpen, activeIndex])

  useEffect(() => {
    const animateDelay = 0.01 // this is required so that Reach modal is rendered before animation is attempted

    gsap.delayedCall(animateDelay, () => {
      setActiveIndex(active[1])
    })
  }, [active])

  useEffect(() => {
    gsap.delayedCall(modalAnimationDurationS, () => {
      if (!isOpen) {
        setActiveIndex(0)
      }
    })
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onDismiss={closeModal} ariaLabel="Image Carousel">
      <CarouselModalWrapper {...handlers}>
        <Container>
          <Grid>
            <GridItem desk={10} deskStart={2}>
              <CarouselModalImages>
                <CarouselModalControls>
                  <IconButton
                    color={colors.light}
                    onClick={previous}
                    label="Previous Item"
                    disabled={activeIndex === 0}
                  >
                    <IconCarouselArrow />
                  </IconButton>
                  <IconButton
                    color={colors.light}
                    onClick={next}
                    label="Next Item"
                    disabled={activeIndex === slides.length - 1}
                  >
                    <IconCarouselArrow />
                  </IconButton>
                </CarouselModalControls>
                {React.Children.toArray(
                  slides.map((slide, slideIndex) => (
                    <CarouselModalImage index={slideIndex - activeIndex}>
                      <RoundedImage>
                        <GatsbyImage
                          image={slide.image.gatsbyImageData}
                          alt={slide.image.description}
                          loading="eager"
                        />
                      </RoundedImage>
                    </CarouselModalImage>
                  ))
                )}
              </CarouselModalImages>
              <CarouselModalContents ref={contentWrap}>
                {React.Children.toArray(
                  slides.map((slide, slideIndex) => (
                    <CarouselModalContent index={slideIndex - activeIndex}>
                      <CarouselModalContentInner
                        ref={el => (contentInners.current[slideIndex] = el)}
                      >
                        <Grid desk={10}>
                          <GridItem desk={3} deskL={4}>
                            <Heading2>{slide.title}</Heading2>
                          </GridItem>
                          {slide.content && (
                            <GridItem
                              desk={6}
                              deskStart={5}
                              deskL={5}
                              deskLStart={6}
                            >
                              <RichText
                                content={slide.content}
                                headingSize={'small'}
                                paragraphSize={'small'}
                                customAnimate={true}
                              />
                            </GridItem>
                          )}
                        </Grid>
                      </CarouselModalContentInner>
                    </CarouselModalContent>
                  ))
                )}
              </CarouselModalContents>
            </GridItem>
          </Grid>
        </Container>
        <CarouselModalClose>
          <IconButton
            color={colors.blue}
            fill={colors.light}
            onClick={closeModal}
            fromDirection={`bottom`}
            label="Close Modal"
            small={true}
          >
            <Close />
          </IconButton>
        </CarouselModalClose>
      </CarouselModalWrapper>
    </Modal>
  )
}

CarouselModal.propTypes = {
  slides: PropTypes.array,
}

export default CarouselModal
