import React, { useState, useRef, useEffect } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useSwipeable } from 'react-swipeable'
import {
  CarouselWrapper,
  CarouselContents,
  CarouselContentsOuter,
  CarouselContentsInner,
  CarouselContent,
  CarouselContentInner,
  CarouselSubSlides,
  CarouselSubSlide,
  CarouselControls,
  CarouselImages,
  CarouselImage,
} from './index.style'
import Grid from '../Grid'
import GridItem from '../GridItem'
import RichText from '../RichText'
import Spacer from '../Spacer'
import RoundedImage from '@components/RoundedImage'
import Container from '@components/Container'
import { Heading1, Label } from '@components/TextStyles'
import Button from '@components/Button'
import IconButton from '@components/IconButton'
import { colors } from '@styles/vars/colors.style'
import IconCarouselArrow from '@svgs/IconCarouselArrow'
import CarouselModal from '@components/CarouselModal'
import TextLink from '@components/TextLink'
import IconArrowUpRight from '@svgs/IconArrowUpRight'
import { breakpoints } from '@styles/vars/breakpoints.style'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'

const Carousel = ({ label, slides, color }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeSubslide, setActiveSubslide] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const contentWrap = useRef()
  const contentInners = useRef([])

  const requiresModal = slides.find(slide => slide.subSlides?.length > 1)

  const openModal = e => {
    if (e) e.preventDefault()
    setShowModal(true)
  }

  const previous = () => {
    if (activeIndex !== 0) setActiveIndex(activeIndex - 1)
  }

  const next = () => {
    if (activeIndex < slides.length - 1) setActiveIndex(activeIndex + 1)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (window.innerWidth < breakpoints.desk) {
        next()
      }
    },
    onSwipedRight: () => {
      if (window.innerWidth < breakpoints.desk) {
        previous()
      }
    },
  })

  useEffect(() => {
    if (!contentWrap.current || !contentInners.current[activeIndex]) return

    if (window.innerWidth >= 1024) {
      gsap.set(contentWrap.current, {
        clearProps: 'all',
      })
      return
    }

    const height =
      contentInners.current[activeIndex].getBoundingClientRect().height

    gsap.to(contentWrap.current, {
      height: height,
      duration: 0.5,
    })
  }, [activeIndex])

  useEffect(() => {
    const resizeContent = () => {
      if (!contentWrap.current || !contentInners.current[activeIndex]) return

      if (window.innerWidth >= 1024) {
        gsap.set(contentWrap.current, {
          clearProps: 'all',
        })
        return
      }

      const height =
        contentInners.current[activeIndex].getBoundingClientRect().height

      gsap.set(contentWrap.current, {
        height: height,
      })
    }

    window.addEventListener('resize', resizeContent)

    return () => {
      window.removeEventListener('resize', resizeContent)
    }
  }, [activeIndex])

  return (
    <CarouselWrapper bgColor={color} {...handlers}>
      <Container>
        <Grid>
          <GridItem
            tabletL={5}
            tabletLAlign="center"
            tabletLOrder={2}
            tabletLStart={7}
            desk={5}
            deskAlign="center"
            deskOrder={2}
            deskStart={7}
            deskL={6}
            deskLStart={6}
          >
            <CarouselImages>
              {React.Children.toArray(
                slides.map(
                  (slide, slideIndex) =>
                    slide.subSlides && (
                      <CarouselImage index={slideIndex - activeIndex}>
                        {slide.subSlides[0].title && (
                          <Label color={colors.light} dot>
                            {slide.subSlides[0].title}
                          </Label>
                        )}
                        <RoundedImage>
                          {slide?.subSlides[0]?.image ? (
                            <GatsbyImage
                              image={
                                slide?.subSlides[0]?.image?.gatsbyImageData
                              }
                              alt={slide?.subSlides[0]?.image?.description}
                              loading="eager"
                            />
                          ) : null}
                        </RoundedImage>
                      </CarouselImage>
                    )
                )
              )}
            </CarouselImages>
          </GridItem>
          <GridItem
            tabletL={5}
            tabletLStart={1}
            desk={5}
            deskStart={1}
            deskL={3}
            deskLStart={2}
          >
            <CarouselContentsOuter>
              <CarouselContentsInner>
                {label && (
                  <>
                    <AnimateFadeIn>
                      <Label dot color={colors.blue}>
                        {label}
                      </Label>
                    </AnimateFadeIn>
                    <Spacer size={[12, 30]} />
                  </>
                )}
                <CarouselContents ref={contentWrap}>
                  {React.Children.toArray(
                    slides.map((slide, slideIndex) => (
                      <CarouselContent index={slideIndex - activeIndex}>
                        <CarouselContentInner
                          ref={el => (contentInners.current[slideIndex] = el)}
                        >
                          <Heading1>
                            <AnimateSplitText>{slide.title}</AnimateSplitText>
                          </Heading1>
                          <Spacer size={[18, 36]} />
                          {slide.content && (
                            <RichText
                              content={slide.content}
                              headingSize="small"
                              paragraphSize="small"
                            />
                          )}
                          {slide.subSlides && slide.subSlides.length > 1 && (
                            <>
                              <CarouselSubSlides>
                                {React.Children.toArray(
                                  slide.subSlides.map(
                                    (subSlide, subSlideIndex) => (
                                      <CarouselSubSlide>
                                        <TextLink
                                          onClick={() => {
                                            setActiveSubslide([
                                              slideIndex,
                                              subSlideIndex,
                                            ])
                                            openModal()
                                          }}
                                          type="button"
                                        >
                                          {subSlide.title}
                                        </TextLink>
                                      </CarouselSubSlide>
                                    )
                                  )
                                )}
                              </CarouselSubSlides>
                              {slide.subSlideCtaText && (
                                <>
                                  <Spacer size={[18, 22]} />
                                  <Button
                                    onClick={openModal}
                                    iconRight={<IconArrowUpRight />}
                                    iconOrientation="vertical"
                                  >
                                    {slide.subSlideCtaText}
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </CarouselContentInner>
                      </CarouselContent>
                    ))
                  )}
                </CarouselContents>
              </CarouselContentsInner>
              <CarouselControls>
                <IconButton
                  onClick={previous}
                  label="Previous Item"
                  fromDirection="left"
                  disabled={activeIndex === 0}
                >
                  <IconCarouselArrow />
                </IconButton>
                <IconButton
                  onClick={next}
                  label="Next Item"
                  fromDirection="left"
                  disabled={activeIndex === slides.length - 1}
                >
                  <IconCarouselArrow />
                </IconButton>
              </CarouselControls>
            </CarouselContentsOuter>
          </GridItem>
        </Grid>
      </Container>
      {requiresModal && (
        <CarouselModal
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
          slides={slides[activeIndex].subSlides}
          active={activeSubslide}
        />
      )}
    </CarouselWrapper>
  )
}

Carousel.propTypes = {
  label: PropTypes.string,
  slides: PropTypes.array,
  color: PropTypes.string,
}

export default Carousel
