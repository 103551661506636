import { matchContainerPadding } from '@components/Container/index.style'
import { IconButtonMain } from '@components/IconButton/index.style'
import { RoundedImageWrapper } from '@components/RoundedImage/index.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { mq } from '@styles/vars/media-queries.style'
import { Label } from '@styles/vars/textStyles.style'
import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { Heading2 } from '@styles/vars/textStyles.style'

export const CarouselModalWrapper = styled.div`
  background: ${colors.darkblue};
  height: 100vh;
  overflow: scroll;
  padding-bottom: 4.8rem;
  width: 100%;
`

export const CarouselModalClose = styled.div`
  position: absolute;
  ${matchContainerPadding('right')}
  ${matchContainerPadding('top')}
  z-index: 2;

  ${mq.desk} {
    right: 4.8rem;
    top: 3.2rem;
  }

  svg {
    ${clamp('height', 14, 18)};
    ${clamp('width', 14, 18)};
  }
`

export const CarouselModalControls = styled.div`
  bottom: -6.4rem;
  display: flex;
  justify-content: space-between;
  left: 50%;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;

  ${mq.desk} {
    bottom: auto;
    left: calc(50vw / -6);
    right: calc(50vw / -6);
    top: 50%;
    transform: translateY(-50%);
  }

  ${IconButtonMain} {
    pointer-events: auto;

    &:nth-child(1) {
      margin-right: 1.2rem;
      transform: rotateZ(180deg);
    }
  }

  svg {
    ${clamp('height', 14, 16)};
    ${clamp('width', 14, 16)};
  }
`

export const CarouselModalImages = styled.div`
  ${clamp('height', 200, 450, breakpoints.mobile, breakpoints.tabletL)};
  margin-top: 8.5rem;
  position: relative;

  ${mq.desk} {
    height: calc(100vh - 12rem - 20.8rem);
    margin-top: 12rem;
  }
`

export const CarouselModalImage = styled.div.attrs(props => ({
  style: {
    opacity: props.index === 0 ? 1 : 0,
    pointerEvents: props.index === 0 ? `auto` : `none`,
    transition: `opacity ${props.index === 0 ? 0.6 : 0}s ${cubicBezier.inOut} ${
      props.index === 0 ? 0 : 0.6
    }s`,
    zIndex: props.index === 0 ? 2 : 1,
  },
}))`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  ${RoundedImageWrapper} {
    height: 100%;

    > div {
      height: 100%;
      transform: scale(${props => (props.index === 0 ? 1 : 1.1)});
      transition: ${props =>
        props.index === 0
          ? `transform 1.2s ${cubicBezier.out}`
          : `transform 0s ${cubicBezier.out} .6s`};
    }
  }

  ${Label} {
    opacity: ${props => (props.index === 0 ? 1 : 0)};
    position: absolute;
    ${clamp('left', 24, 48)};
    ${clamp('top', 20, 44)};
    transition: opacity 1s ${cubicBezier.out};
    z-index: 2;
  }
`

export const CarouselModalContents = styled.div`
  display: grid;
  margin-top: 8.8rem;
  overflow: hidden;
  position: relative;

  ${mq.desk} {
    margin-top: 2.4rem;
  }
`

export const CarouselModalContent = styled.div.attrs(props => ({
  style: {
    pointerEvents: props.index !== 0 ? `none` : `auto`,
    opacity: props.index === 0 ? 1 : 0,
    transition: `opacity 0.6s ${
      props.index !== 0 ? cubicBezier.out : cubicBezier.inOut
    } ${props.index !== 0 ? 0 : 0.4}s`,
  },
}))`
  color: ${colors.light};
  grid-area: 1 / 1;

  ${Heading2} {
    margin-bottom: 2.2rem;
  }
`

export const CarouselModalContentInner = styled.div``
