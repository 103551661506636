import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const FullVideoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80vh;
  justify-content: center;
  position: relative;

  ${mq.desk} {
    height: 100vh;
  }
`

export const FullVideoVideo = styled.div`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 1;

  &:after {
    background: linear-gradient(
      180deg,
      rgba(33, 34, 63, 0) 0%,
      rgba(33, 34, 63, 0.6) 86.12%
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    transform: translateZ(0);
    will-change: transform;
    z-index: 2;
  }
`

export const FullVideoText = styled.div`
  color: ${colors.light};
  position: relative;
  text-align: center;
  z-index: 3;

  ${mq.tabletP} {
    max-width: 90rem;
    width: 68%;
  }
`
