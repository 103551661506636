import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Player from '@vimeo/player'
import IconVideoPlayButton from '@svgs/IconVideoPlayButton'
import {
  VimeoPlayerMain,
  VimeoPlayerEmbed,
  VimeoPlayerOverlay,
  VimeoPlayButton,
} from './index.style'

const VimeoPlayer = ({ id, options = {}, vw, vh, readyEvent, playState }) => {
  const videoRef = useRef()
  const videoPlayer = useRef()
  const [playing, setPlaying] = useState(options.background ? true : false)
  const [aspectRatio, setAspectRatio] = useState(null)

  const play = () => {
    videoPlayer.current.play().catch(error => {
      console.log(error)
    })
  }

  useLayoutEffect(() => {
    const embedOptions = {
      id,
      ...options,
    }

    videoPlayer.current = new Player(videoRef.current, embedOptions)

    Promise.all([
      videoPlayer.current.getVideoWidth(),
      videoPlayer.current.getVideoHeight(),
    ]).then(dimensions => {
      setAspectRatio(
        options.background
          ? dimensions[0] / dimensions[1]
          : dimensions[1] / dimensions[0]
      )
    })

    const singleReadyEvent = () => {
      readyEvent()
      videoPlayer.current.off('timeupdate', singleReadyEvent)
    }

    videoPlayer.current.on('pause', () => setPlaying(false))
    videoPlayer.current.on('ended', () => setPlaying(false))
    videoPlayer.current.on('play', () => setPlaying(true))
    readyEvent && videoPlayer.current.on('timeupdate', singleReadyEvent)

    if (!options.autoplay) videoPlayer.current.pause()

    return () => {
      videoPlayer.current.off('pause')
      videoPlayer.current.off('ended')
      videoPlayer.current.off('play')
    }
  }, [id, options, readyEvent])

  useEffect(() => {
    if (options.autoplay) return

    if (playState) {
      videoPlayer.current.play()
    }
  }, [options, playState])

  return (
    <VimeoPlayerMain aspectRatio={aspectRatio} background={options.background}>
      {!options.autoplay && !options.background && (
        <VimeoPlayerOverlay
          aria-label="Play video"
          playing={playing}
          onClick={play}
        >
          <VimeoPlayButton>
            <IconVideoPlayButton />
          </VimeoPlayButton>
        </VimeoPlayerOverlay>
      )}

      <VimeoPlayerEmbed
        ref={videoRef}
        playing={playing}
        aspectRatio={aspectRatio}
        background={options.background}
        vw={vw}
        vh={vh}
      />
    </VimeoPlayerMain>
  )
}

VimeoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.object,
  vw: PropTypes.number,
  vh: PropTypes.number,
  readyEvent: PropTypes.func,
  playState: PropTypes.bool,
}

VimeoPlayer.defaultProps = {
  vw: 100,
  vh: 100,
}

export default VimeoPlayer
