import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import {
  HeroVideo,
  HeroMain,
  HeroWrapper,
  HeroLogo,
  HeroLogoInner,
  HeroButton,
  HeroButtons,
  HeroButtonInner,
  HeroLogoImage,
} from './index.style'
import VimeoPlayer from '@components/VimeoPlayer'
import Button from '@components/Button'
import IconArrowDown from '@svgs/IconArrowDown'
import { useStore } from '@Store/index'
import { anchorScroll } from '@utils'

const Hero = ({ title, logo, video, contentBlocks }) => {
  const [store] = useStore()

  const { smoothScroll } = store

  const [videoReady, setVideoReady] = useState(false)

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0% 0% 0% 0%',
  })

  return (
    <HeroWrapper ref={ref}>
      <HeroVideo visible={inView && videoReady}>
        <VimeoPlayer
          id={video}
          options={{
            background: true,
            autoplay: true,
            quality: '1080p',
          }}
          vh={105}
          readyEvent={() => setVideoReady(true)}
        />
      </HeroVideo>
      {logo && (
        <HeroMain>
          <HeroLogo alt={title}>
            <HeroLogoInner visible={inView} delay={2}>
              <HeroLogoImage src={logo.file.url} />
            </HeroLogoInner>
          </HeroLogo>
        </HeroMain>
      )}
      <HeroButton>
        <HeroButtonInner visible={inView} delay={2.4}>
          <Button
            iconRight={<IconArrowDown />}
            onClick={() => anchorScroll(smoothScroll, '#micro-intro')}
          >
            Explore Details
          </Button>
        </HeroButtonInner>
      </HeroButton>
      <HeroButtons>
        {React.Children.toArray(
          contentBlocks.map((block, blockIndex) => {
            if (block.__typename === 'ContentfulMicrositeContentBlock') {
              return (
                <HeroButtonInner
                  visible={inView}
                  delay={blockIndex * 0.2 + 2.4}
                >
                  <Button
                    iconRight={<IconArrowDown />}
                    iconOrientation="vertical"
                    onClick={() =>
                      anchorScroll(smoothScroll, `#micro-block-${blockIndex}`)
                    }
                  >
                    {block.anchorButtonLabel}
                  </Button>
                </HeroButtonInner>
              )
            } else return ``
          })
        )}
      </HeroButtons>
    </HeroWrapper>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.object,
  video: PropTypes.string,
  contentBlocks: PropTypes.array,
}

export default Hero
