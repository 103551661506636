import styled, { css } from 'styled-components'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { clamp } from '@styles/utils/conversion.style'

export const IconButtonMain = styled.button`
  background: ${props => (props.fill ? props.fill : `transparent`)};
  color: ${props => props.color};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${props => clamp('height', props.small ? 36 : 40, 45)};
  text-decoration: none;
  border: 0.1rem solid ${props => (props.fill ? props.fill : props.color)};
  border-radius: 50%;
  overflow: hidden;
  white-space: nowrap;
  ${props => clamp('width', props.small ? 36 : 40, 45)};
  transition: opacity 0.6s ${cubicBezier.out};

  &:active {
    color: ${props => props.color};
  }

  &[disabled] {
    opacity: 0.2;
    cursor: default;
  }

  > svg {
    position: relative;
    z-index: 2;
  }
`

const Icon = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: ${props => props.iconWidth || 1}em;
  z-index: 2;

  svg {
    transition: transform 0.4s ${cubicBezier.out};
    width: 100%;

    &:nth-child(1) {
      transition-delay: 0.3s;
    }

    &:nth-child(2) {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition-timing-function: ${cubicBezier.in};
      transition-delay: 0s;
      width: 100%;
    }

    ${IconButtonMain}:not([disabled]):hover & {
      &:nth-child(1) {
        transition-timing-function: ${cubicBezier.in};
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transform: translateY(0);
        transition-timing-function: ${cubicBezier.out};
        transition-delay: 0.3s;
      }
    }
  }
`

export const IconLeft = styled.span`
  ${Icon};

  svg {
    &:nth-child(2) {
      transform: translateX(-100%);
    }

    ${IconButtonMain}:not([disabled]):hover & {
      &:nth-child(1) {
        transform: translateX(100%);
      }
    }
  }
`

export const IconBottom = styled.span`
  ${Icon};

  svg {
    &:nth-child(2) {
      transform: translateY(100%);
    }

    ${IconButtonMain}:not([disabled]):hover & {
      &:nth-child(1) {
        transform: translateY(-100%);
      }
    }
  }
`

export const IconButtonBackground = styled.span`
  background: linear-gradient(
    ${props => (props.fromDirection === 'left' ? `270` : `180`)}deg,
    rgba(169, 223, 241, 0) 0%,
    rgba(169, 223, 241, 0) 33%,
    rgba(169, 223, 241, 1) 66%,
    rgba(169, 223, 241, 1) 100%
  );
  ${props =>
    props.fromDirection === 'left'
      ? `
    bottom: 0;
    left: -200%;
  `
      : `
    bottom: -200%;
    left: 0;
  `}
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  transform: translateZ(0);
  transition: transform 0.6s ${cubicBezier.inOut};
  z-index: 1;

  ${IconButtonMain}:not([disabled]):hover & {
    transform: translateY(0) translateZ(0);
    transform: ${props =>
      props.fromDirection === 'left' ? `translateX(66%)` : `translateY(-66%)`};
  }
`
