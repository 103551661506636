import React from 'react'
import PropTypes from 'prop-types'
import { IconButtonMain, IconLeft, IconBottom } from './index.style'
import { colors } from '@styles/vars/colors.style'

/**
 *
 * @prop label
 * If defined this adds an aria-label to the component. Required for icon only buttons.
 */

const IconButton = ({
  disabled,
  fromDirection,
  small,
  color,
  fill,
  label,
  onClick,
  children,
}) => {
  return (
    <>
      <IconButtonMain
        as="button"
        disabled={disabled}
        aria-label={label}
        small={small}
        onClick={onClick}
        color={color}
        fill={fill}
      >
        {fromDirection === 'left' && (
          <IconLeft fromDirection={fromDirection}>
            {children}
            {children}
          </IconLeft>
        )}
        {fromDirection === 'bottom' && (
          <IconBottom fromDirection={fromDirection}>
            {children}
            {children}
          </IconBottom>
        )}
      </IconButtonMain>
    </>
  )
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  fromDirection: PropTypes.oneOf(['left', 'bottom']).isRequired,
  small: PropTypes.bool,
  color: PropTypes.string,
  fill: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: function (props, propName) {
    if (
      props[propName] === undefined ||
      typeof props[propName] !== 'function'
    ) {
      return new Error('Please provide an onClick function!')
    }
  },
  children: PropTypes.node.isRequired,
}

IconButton.defaultProps = {
  color: colors.dark,
  fromDirection: 'left',
}

export default IconButton
