import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { FullImageWrapper, FullImageImage, FullImageText } from './index.style'
import { Heading2, Super1 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSplitText from '@components/animation/AnimateSplitText'

const FullImage = ({ id, title, subtitle, image, inset }) => {
  const InsetContainer = ({ inset, children }) => {
    if (inset) {
      return <Container>{children}</Container>
    } else {
      return children
    }
  }
  return (
    <FullImageWrapper id={id} inset={inset}>
      <InsetContainer inset={inset}>
        <FullImageImage overlay={title || subtitle}>
          {image && (
            <GatsbyImage image={image.gatsbyImageData} alt={image.description} />
          )}
        </FullImageImage>
      </InsetContainer>
      {(title || subtitle) && (
        <FullImageText>
          <Container>
            {title && (
              <Super1>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Super1>
            )}
            {title && subtitle && <Spacer size={35} />}
            {subtitle && (
              <Heading2>
                <AnimateSplitText>{subtitle}</AnimateSplitText>
              </Heading2>
            )}
          </Container>
        </FullImageText>
      )}
    </FullImageWrapper>
  )
}

FullImage.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  inset: PropTypes.bool,
}

export default FullImage
