import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const FullImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  position: relative;

  ${props =>
    props.inset &&
    `
    height: 50vh;
    ${clamp('min-height', 380, 1080)};
    ${clamp('padding-left', 0, 152)};
    ${clamp('padding-right', 0, 152)};
  `}
`

export const FullImageImage = styled.div`
  height: 100%;
  position: relative;
  user-select: none;
  z-index: 1;

  > div {
    height: 100%;
  }

  ${props =>
    props.overlay &&
    `
  &:after {
    background: linear-gradient(
      180deg,
      rgba(33, 34, 63, 0) 0%,
      rgba(33, 34, 63, 0.6) 86.12%
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    transform: translateZ(0);
    will-change: transform;
    z-index: 2;
  }`}
`

export const FullImageText = styled.div`
  color: ${colors.light};
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  z-index: 3;

  ${mq.tabletP} {
    max-width: 90rem;
    width: 68%;
  }
`
