import styled, { css } from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { clamp, getClampValue } from '@styles/utils/conversion.style'
import { ButtonMain } from '@components/Button/index.style'
import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

export const HeroWrapper = styled.div`
  background: lightblue;
  height: calc(100vh + ${getClampValue(12, 24)});
  overflow: hidden;
  position: relative;
`

export const HeroVideo = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(${props => (props.visible ? 1 : 1.5)});
  transition: transform 3s ${cubicBezier.out};
  user-select: none;
  z-index: 1;

  &:before,
  &:after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:before {
    background-color: ${colors.darkblue};
    opacity: ${props => (props.visible ? 0 : 1)};
    transition: opacity 1.4s ${cubicBezier.inOut};
    z-index: 3;
  }

  &:after {
    background: linear-gradient(
      180deg,
      rgba(33, 34, 63, 0.5004595588235294) 0%,
      rgba(33, 34, 63, 0.4976584383753502) 50%,
      rgba(33, 34, 63, 0) 100%
    );
    pointer-events: none;
    transform: translateZ(0);
    z-index: 2;
  }
`

export const HeroMain = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  z-index: 2;
`

export const HeroLogo = styled.div`
  align-items: center;
  color: ${colors.light};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const HeroLogoInner = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: transform;

  &:first-child {
    ${clamp('margin-bottom', 15, 35)};
    ${clamp('padding-left', 20, 45)};
    ${clamp('padding-right', 10, 25)};
  }

  svg,
  img {
    height: auto;
    margin: 0 auto;
    max-height: 27rem;
    ${clamp('max-width', 400, 1000)};
    object-fit: contain;
    transform: translateY(${props => (props.visible ? 0 : 105)}%);
    transition: transform 0.8s ${cubicBezier.out};
    transition-delay: ${props => (props.delay ? props.delay : 0)}s;
    width: 85%;
  }
`

const heroButtons = css`
  bottom: 9.6rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
`

export const HeroButton = styled.div`
  ${heroButtons};
  ${mq.tabletL} {
    display: none;
  }
`

export const HeroButtons = styled.div`
  ${heroButtons};
  display: none;

  ${mq.tabletL} {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  ${ButtonMain} {
    margin: 0 0.9rem;
  }
`

export const HeroButtonInner = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 100)}%);
  transition: transform 0.8s ${cubicBezier.out}, opacity 0.6s ${cubicBezier.out};
  transition-delay: ${props => props.delay}s;
  will-change: transform;
`

export const HeroLogoImage = styled.img`
  width: 100%;
  height: auto;
`
