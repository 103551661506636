import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import {
  LinksMain,
  LinksWrapper,
  LinksInner,
  LinksList,
  LinksListItem,
} from './index.style'
import { Heading1, TextBody, TextBodySmall } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import Button from '@components/Button'
import IconArrowUpRight from '@svgs/IconArrowUpRight'

const Links = ({
  title,
  titleSuffix,
  subtitlePrefix,
  links,
  seperatorLine,
  secondaryLinks,
  colors,
}) => {
  return (
    <LinksWrapper id="marketing-tools">
      <Container>
        <LinksMain bgColor={colors[0]} bgGradient={colors[1]}>
          <LinksInner>
            <Heading1>
              {title} <br />
              {titleSuffix}
            </Heading1>
            <Spacer size={20} />
            <TextBody>
              {subtitlePrefix} <br />
              <i>{title}</i>
            </TextBody>
            <Spacer size={[24, 58]} />
          </LinksInner>
          <hr />
          <Spacer size={[24, 58]} />
          <LinksList>
            {React.Children.toArray(
              links.map(link => {
                return (
                  <LinksListItem>
                    <Button
                      href={link.url ? link.url : link.resource?.file?.url}
                      target="_blank"
                      type="externalLink"
                      iconRight={<IconArrowUpRight />}
                      download={link.url ? false : true}
                      iconOrientation="vertical"
                    >
                      {link.text}
                    </Button>
                  </LinksListItem>
                )
              })
            )}
          </LinksList>
          <Spacer size={[24, 40]} />
          {secondaryLinks && (
            <>
              <hr />
              <Spacer size={[24, 58]} />
              <LinksInner>
                <TextBodySmall>{seperatorLine}</TextBodySmall>
                <Spacer size={24} />
              </LinksInner>
              <LinksList>
                {React.Children.toArray(
                  secondaryLinks.map(link => {
                    return (
                      <LinksListItem>
                        <Button
                          href={link.url ? link.url : link.resource?.file?.url}
                          target="_blank"
                          type="externalLink"
                          iconRight={<IconArrowUpRight />}
                          download={link.url ? false : true}
                          iconOrientation="vertical"
                        >
                          {link.text}
                        </Button>
                      </LinksListItem>
                    )
                  })
                )}
              </LinksList>
            </>
          )}
        </LinksMain>
      </Container>
    </LinksWrapper>
  )
}

Links.propTypes = {
  title: PropTypes.string,
  titleSuffix: PropTypes.string,
  subtitlePrefix: PropTypes.string,
  links: PropTypes.array,
  seperatorLine: PropTypes.string,
  secondaryLinks: PropTypes.array,
  colors: PropTypes.array,
}

export default Links
