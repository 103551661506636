import React from 'react'

const IconCarouselArrow = ({
  width = 17,
  height = 9,
  stroke = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 9"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M16.2002 4.71831H0.000196815M16.2002 4.71831L12.3818 8.53666M16.2002 4.71831L12.3818 0.899902"
        stroke={stroke}
      />
    </svg>
  )
}

export default IconCarouselArrow
