import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Hero from '@components/Microsite/Hero'
import ImageContent from '@components/ImageContent'
import Links from '@components/Microsite/Links'
import Spacer from '@components/Spacer'
import FullVideo from '@components/FullVideo'
import Carousel from '@components/Carousel'
import FullImage from '@components/FullImage'
import Footer from '@components/Footer'
import { formatStringToClass, anchorScroll } from '@utils'
import { useStore } from '@Store'

const Microsite = ({ data, location }) => {
  const {
    seo,
    slug,
    title,
    heroLogo,
    heroVideoVimeoId,
    intro,
    marketingTools,
    marketingLinks,
    marketingToolsBgColors,
    contentBlocks,
    ship,
  } = data.contentfulMicrosite

  const [store] = useStore()
  const { smoothScroll } = store

  useEffect(() => {
    const hash = window.location.hash

    if (hash && smoothScroll) {
      setTimeout(() => {
        anchorScroll(smoothScroll, hash, 0)
      }, 500)
    }
  }, [smoothScroll])

  return (
    <>
      <Seo data={seo} slug={slug} />
      <Hero
        title={title}
        logo={heroLogo}
        video={heroVideoVimeoId}
        contentBlocks={contentBlocks}
      />
      <ImageContent
        id="micro-intro"
        image={intro.imageTall}
        imageLabel={intro.imageLabel}
        content={intro.content}
        flip={intro.flip}
        intro={true}
      />
      <Links
        title={title}
        titleSuffix=" Marketing Tools"
        subtitlePrefix="Everything you need to market "
        links={marketingTools}
        seperatorLine="Start planning your clients’ vacation with a personalized website"
        secondaryLinks={marketingLinks}
        colors={marketingToolsBgColors}
      />
      <Spacer size={[100, 200]} />
      {React.Children.toArray(
        contentBlocks.map((contentBlock, contentBlockIndex) => {
          switch (contentBlock.__typename) {
            case 'ContentfulMicrositeContentBlock':
              return (
                <section
                  id={formatStringToClass(
                    contentBlock.anchorButtonLabel,
                    false
                  )}
                >
                  {contentBlock.introVimeoVideoId ? (
                    <FullVideo
                      id={`micro-block-${contentBlockIndex}`}
                      title={contentBlock.title}
                      subtitle={contentBlock.subtitle}
                      video={contentBlock.introVimeoVideoId}
                    />
                  ) : contentBlock.introBackgroundImage ? (
                    <FullImage
                      id={`micro-block-${contentBlockIndex}`}
                      title={contentBlock.title}
                      subtitle={contentBlock.subtitle}
                      image={contentBlock.introBackgroundImage}
                    />
                  ) : (
                    ``
                  )}
                  {React.Children.toArray(
                    contentBlock.blocks.map(block => {
                      switch (block.__typename) {
                        case 'ContentfulImageContentBlock':
                          return (
                            <ImageContent
                              image={block.imageTall}
                              imageLabel={block.imageLabel}
                              content={block.content}
                              flip={block.flip}
                            />
                          )
                        case 'ContentfulCarouselBlock':
                          return (
                            <Carousel
                              label={block.label}
                              slides={block.items}
                              color={block.backgroundColor}
                            />
                          )
                        default:
                          return null
                      }
                    })
                  )}
                </section>
              )
            case 'ContentfulCarouselBlock':
              return (
                <section>
                  <Carousel
                    label={contentBlock.label}
                    slides={contentBlock.items}
                    color={contentBlock.backgroundColor}
                  />
                </section>
              )
            default:
              return null
          }
        })
      )}
      {ship && <FullImage image={ship} inset={true} />}
      <Footer location={location} />
    </>
  )
}

export default Microsite

export const pageQuery = graphql`
  query Microsite($slug: String!) {
    contentfulMicrosite(slug: { eq: $slug }) {
      seo {
        ...SEO
      }
      slug
      title
      heroLogo {
        file {
          url
        }
      }
      heroVideoVimeoId
      intro {
        ...ImageContentBlock
      }
      marketingTools {
        ...Link
      }
      marketingLinks {
        ...Link
      }
      marketingToolsBgColors
      contentBlocks {
        ... on Node {
          ...MicrositeContentBlocks
          ... on ContentfulCarouselBlock {
            ...CarouselBlock
          }
        }
      }
      ship {
        gatsbyImageData
        description
      }
    }
  }
`
